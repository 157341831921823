<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">HBase</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3" v-if="isSuccessful.response">
      <div class="w-100 text-center">
        <h2 class="mb-1">Welcome</h2>
        <p class="mb-2">{{ isSuccessful.message }}</p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="loginRoute()"
        >
          Back to Home
        </b-button>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>

    <div class="misc-inner p-2 p-sm-3" v-else>
      <div class="w-100 text-center">
        <h2 class="mb-1">Set your password</h2>

        <validation-observer ref="setPasswordForm" #default="{ invalid }">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Username" label-for="v-username">
                <b-form-input v-model="username" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label-for="account-password" label="Password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="account-password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePassword"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label-for="account-retype-password"
                label="Retype Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="account-retype-password"
                      v-model="retypePassword"
                      :type="passwordFieldTypeRetype"
                      :state="errors.length > 0 ? false : null"
                      name="retype-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="text-right">
              <b-button
                :disabled="invalid"
                size="sm"
                @click="onSubmit()"
                variant="primary"
                class="bg-darken-4"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
        <b-img fluid :src="imgUrl" alt="Not authorized page" />
      </div>
    </div>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import store from "@/store/index";
import userApi from "@api/users";

import { required, password } from "@validations";
import md5 from "md5";

export default {
  components: {
    VuexyLogo,
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require("@/assets/images/pages/not-authorized-dark.svg");
        return this.downImg;
      }
      return this.downImg;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  data: () => ({
    required,
    password,
    downImg: require("@/assets/images/pages/not-authorized.svg"),
    username: "",
    password: "",
    retypePassword: "",
    passwordFieldType: "password",
    passwordFieldTypeRetype: "password",
    isSuccessful: {
      response: false,
      message: "",
    },
  }),
  mounted() {
    //
  },
  methods: {
    loginRoute() {
      this.$router.push("/login");
    },
    onSubmit() {
      const data = {
        token: this.$route.query.t,
        email: this.$route.query.email,
        username: this.username,
        password: md5(this.password),
      };

      userApi
        .setPassword(data)
        .then(({ data }) => {
          if (data) {
            this.isSuccessful = {
              message: data.message,
              response: true,
            };
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    togglePassword() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype =
        this.passwordFieldTypeRetype === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
